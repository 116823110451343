<template>
  <HeaderHome />
  <Loader :show="show_loader"/>
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-8 col-md-offset-2">
          <h1>Faça seu Login</h1>
          <p class="text-justify">
            Preencha seus dados abaixo e acesse seu painel ou crie uma conta
            agora mesmo.
          </p>
          <div class="container-fluid form-box2">
            <form ref="login" @submit.prevent="login">
              <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>E-mail *</label>
                  <input v-model="email" type="email" class="form-control" required="required" name="email" placeholder="E-mail" autocomplete="off" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Senha *</label>
                  <input v-model="password" type="password" class="form-control" required="" name="password" placeholder="Senha" autocomplete="off" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group text-center">
                  <button @click.prevent="login" class="btn btn-success">
                    EFETUAR LOGIN
                  </button>
                </div>
              </div>
                <div class="col-md-12">
                  <p class="text-center">
                    <VLink href="/esqueci-minha-senha/">Esqueci minha senha</VLink>
                  </p>
                </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <hr>
        OU
        <hr>
      </div>
    </div>

    <div class="container">
      <div class="row body-box">
        <div class="col-md-8 col-md-offset-2">
          <h1>Cadastre-se</h1>
          <p class="text-justify">
            Preencha os dados abaixo para criar uma conta na Ticket Experience.
            Com o seu usuário e senha em mãos, você consegue se cadastra em um
            evento com poucos cliques.
          </p>
          <div class="container-fluid form-box2">
            <div class="row form-group">
              <div class="col-xs-12">
                <ul class="nav nav-pills nav-justified thumbnail setup-panel">
                  <li :class="current_step == 1 ? 'active' : ''">
                    <a @click.prevent="goto_step1" href="#step-1">
                      <h4 class="list-group-item-heading">Passo 1</h4>
                      <p class="list-group-item-text">Dados Pessoais</p>
                    </a>
                  </li>
                  <li :class="[ step2 ? '' : 'disabled', current_step == 2 ? 'active' : '', ]" >
                    <a @click.prevent="goto_step2" href="#step-2">
                      <h4 class="list-group-item-heading">Passo 2</h4>
                      <p class="list-group-item-text">Dados complementares</p>
                    </a>
                  </li>
                  <li :class="[step3 ? '' : 'disabled', current_step == 3 ? 'active' : '', ]" >
                    <a @click.prevent="goto_step3" href="#step-3">
                      <h4 class="list-group-item-heading">Passo 3</h4>
                      <p class="list-group-item-text">
                        Finalização do Cadastro
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <form @submit.prevent ref="formstep1">
              <div class="row setup-content" v-show="current_step == 1">
                <div class="col-xs-12">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Dados Pessoais</h3>
                      </div>
                      <div class="col-md-6 text-right padding-h3">
                        * Campos obrigatórios.
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Nome *</label>
                        <input v-model="usuario.first_name" type="text" class="form-control" required />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Sobrenome *</label>
                        <input v-model="usuario.last_name" type="text" class="form-control" required />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Data de nascimento *</label>
                        <input v-model="usuario.birthday" type="text" class="form-control" required placeholder="__/__/____" v-mask="'##/##/####'" autocomplete="off" maxlength="10"/>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>CPF *</label>
                        <input v-model="usuario.cpf" @focusout="ValidCPF" type="text" class="form-control" required placeholder="___.___.___-__" v-mask="'###.###.###-##'" autocomplete="off" maxlength="14" />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Sexo *</label>
                        <select required class="form-control" v-model="usuario.gender_id" >
                          <option value="">Selecione Sexo</option>
                          <option v-for="gender in genders" :value="gender.id" :key="gender.id" > {{ gender.name }} </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>E-mail *</label>
                        <input v-model="usuario.email" type="email" autocomplete="off" class="form-control" required />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Senha *</label>
                        <input v-model="usuario.password" type="password" class="form-control" autocomplete="new-password" required />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Confirmar senha *</label>
                        <input v-model="usuario.password_confirmation" type="password" class="form-control" autocomplete="new-password" required />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <label>
                        <input v-model="usuario.terms" type="checkbox" required />
                        Concordo e aceito os termos de política e privacidade da
                        TKX
                      </label>
                    </div>
                    <div class="col-md-12">
                      <label>
                        <input v-model="usuario.receive_news" type="checkbox" />
                        Desejo receber o calendário Tkx, dicas e oportunidades de
                        parceiros.
                      </label>
                    </div>
                    <div class="col-md-12 text-right">
                      <button @click.prevent="next_step2" class="btn btn-success">
                        Próximo Passo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form @submit.prevent ref="formstep2">
              <div class="row setup-content" v-show="current_step == 2">
                <div class="col-xs-12">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <h3>Dados complementares</h3>
                      </div>
                      <div class="col-md-6 text-right padding-h3">
                        * Campos obrigatórios.
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>E-mail secundário</label>
                        <input v-model="usuario.email_second" type="email" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Telefone *</label>
                        <input v-model="usuario.telephone" type="text" class="form-control" required="" v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off" maxlength="15"/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Celular</label>
                        <input v-model="usuario.cellphone" type="text" v-mask="['(##) ####-####', '(##) #####-####']" class="form-control" autocomplete="off" maxlength="15" />
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <button @click.prevent="next_step3" class="btn btn-success">
                        Próximo Passo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form @submit.prevent ref="formstep3">
              <div class="row setup-content" v-show="current_step == 3">
              <div class="col-xs-12">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <h3>Dados complementares</h3>
                    </div>
                    <div class="col-md-6 text-right padding-h3">
                      * Campos obrigatórios.
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <label>Endereço *</label>
                      <input v-model="usuario.street" type="text" class="form-control" required="" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Número *</label>
                      <input v-model="usuario.number" type="text" class="form-control" required="" maxlength="10" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Complemento</label>
                      <input v-model="usuario.complement" type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Bairro *</label>
                      <input v-model="usuario.neighborhood" type="text" class="form-control" required="" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Estado *</label>
                      <select required class="form-control" v-model="usuario.state_id" @change="change_state" >
                        <option value="">Selecione um Estado</option>
                        <option v-for="state in states" :value="state.id" :key="state.id" >
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Cidade *</label>
                      <select v-model="usuario.city_id" required class="form-control" >
                        <option>Selecione uma Cidade</option>
                        <option v-for="(city, index) in cities" :value="index" :key="index" >
                          {{ city }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12 text-right">
                    <button @click.prevent="next_step3" @click="sign_up" class="btn btn-success" >
                      Efetuar Cadastro
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </div>
          <Atalhos />
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import Auth from "@/api/Auth";
import Loader from "../components/Loader";
import HeaderHome from "@/components/HeaderHome";
import Footer from "@/components/Footer";
import FooterEnd from "@/components/FooterEnd";
import Newsletter from "@/components/Newsletter";
import Atalhos from "../components/Atalhos";
import { mask } from "vue-the-mask";
import States from "@/api/States";
import Cities from "@/api/Cities";
import Genders from "@/api/Genders";
import User from "@/api/User";
import VLink from "../components/VLink";


import Swal from "sweetalert2";

export default defineComponent({
name: "InscricaoPage",
  directives: { mask },
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
    Loader,
    VLink
  },
  data() {
    return {
      id:null,
      slug:null,
      email: "",
      password: "",
      show_loader:true,
      step1: true,
      step2: false,
      step3: false,
      current_step: 1,
      states: [],
      cities: [],
      genders: [],
      usuario: {
        state_id: "",
        city_id: "",
        gender_id: "",
        first_name: "",
        last_name: "",
        email: "",
        email_second: "",
        password: "",
        password_confirmation: "",
        cpf: "",
        birthday: "",
        telephone: "",
        cellphone: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        receive_news: false,
      },
    };
  },
  methods: {
    goto_step1() {
      this.step1 = true;
      this.current_step = 1;
    },
    goto_step2() {
      if (this.step2 == true) {
        this.current_step = 2;
      }
    },
    goto_step3() {
      if (this.step3 == true) {
        this.current_step = 3;
      }
    },
    next_step2() {
      if(this.$refs.formstep1.checkValidity() == false){
        this.$refs.formstep1.reportValidity();
        return false;
      }

      this.step2 = true;
      this.current_step = 2;

    },
    next_step3() {
      if(this.$refs.formstep2.checkValidity() == false){
        this.$refs.formstep2.reportValidity();
        return false;
      }

      this.step3 = true;
      this.current_step = 3;
    },
    change_state() {
      let cities = Cities.get_cities(this.usuario.state_id);

      cities.then((request) => {
        if (request.status == 200) {
          this.cities = request.data;
        }
      });
    },
    sign_up() {
      if(this.$refs.formstep3.checkValidity() == false){
        this.$refs.formstep3.reportValidity();
        return false;
      }
      this.show_loader = true;

      let signup = User.registration(Object.assign({}, this.usuario));

      signup.then((requestCreate) => {
        if(requestCreate.status == 201){
          User.login(this.usuario.email,this.usuario.password).then((request)=>{
            if (request.status == 200) {
              Auth.set_token(request.data.access_token);
              User.profile(request.data.access_token).then((request2) => {
                if (request2.status == 200) {
                  Auth.set_user(request2.data);

                  this.show_loader = false;

                  if(this.$route.params.hash && this.$route.params.lote){
                    this.$router.push("/escolha-o-kit/"+this.id+"/"+this.slug+"/"+this.$route.params.hash+'/'+this.$route.params.lote);
                  }else if(this.$route.params.hash){
                    this.$router.push("/escolha-o-kit/"+this.id+"/"+this.slug+"/"+this.$route.params.hash);
                  }else{
                    this.$router.push("/escolha-o-kit/"+this.id+"/"+this.slug+"/");
                  }

                  window.scrollTo(0, 0);
                }
              });
            }else{
              this.show_loader = false;
            }
          });
        }else{
          if(requestCreate.status == 422){
            let MSGS= "";
            let errors = [];
            errors = requestCreate.data.errors;
            Object.entries(errors).map((value)=>{
              MSGS =  value[1][0]+"<br>"+MSGS ;
            });
            Swal.fire({
              icon: 'error',
              title:'Alguns campos apresentam erro',
              html: MSGS,
              showConfirmButton: true,
            });

            this.goto_step1();
          }
          this.show_loader = false;
        }

      });
    },
    ValidCPF(){
      let valid = this.isValidCPF(this.usuario.cpf);
      if(valid == false){
        this.usuario.cpf = "";
      }
    },
    isValidCPF(cpf) {
      if (typeof cpf !== 'string') return false
      cpf = cpf.replace(/[^\d]+/g, '')
      if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
      cpf = cpf.split('')
      const validator = cpf
          .filter((digit, index, array) => index >= array.length - 2 && digit)
          .map( el => +el )
      const toValidate = pop => cpf
          .filter((digit, index, array) => index < array.length - pop && digit)
          .map(el => +el)
      const rest = (count, pop) => (toValidate(pop)
          .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10

      return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
    },
    login() {
      if(!this.$refs.login.checkValidity()){
        this.$refs.login.reportValidity();
        return;
      }
      this.show_loader = true;

      let user = User.login(this.email, this.password);
      user.then((request) => {
        if (request.status == 200) {
          Auth.set_token(request.data.access_token);
          User.profile(request.data.access_token).then((request2) => {
            if (request2.status == 200) {
              Auth.set_user(request2.data);

              this.show_loader = false;

              if(this.$route.params.hash && this.$route.params.lote){
                this.$router.push("/escolha-o-kit/"+this.id+"/"+this.slug+"/"+this.$route.params.hash+'/'+this.$route.params.lote);
              }else if(this.$route.params.hash){
                this.$router.push("/escolha-o-kit/"+this.id+"/"+this.slug+"/"+this.$route.params.hash);
              }else{
                this.$router.push("/escolha-o-kit/"+this.id+"/"+this.slug+"/");
              }
              window.scrollTo(0, 0);
            }
          });
        }else{
          Swal.fire({
                icon: 'error',
                title: 'E-mail ou senha inválida.',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar:true,
              }
          );
          this.show_loader = false;

        }
      });
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.slug = this.$route.params.slug;

    this.show_loader = false;
    let states = States.get_states();

    states.then((request) => {
      if (request.status == 200) {
        this.states = request.data;
      }
    });

    let genders = Genders.get_genders();

    genders.then((request) => {
      if (request.status == 200) {
        this.genders = request.data;
      }
    });
  }
});
</script>

<style>
</style>
